import React from "react"
import {
  CENTRAL_URL,
  API_URL,
  LOBBY_VERSION_V2,
  WEB_CONFIG,
  GAME_LIST
} from "gatsby-env-variables"
import NProgress from "../components/loading/spinner"
import ScrollToTop from "~/components/scroll-to-top";
import { EVENT_NEW } from "../env";


const defaultState = {
  playUrl: "",
  isLogin: "",
  isShowMenu: true,
  isShowMenuMobile: false,
  isSearch: false,
  categories: [],
  centralList: {
    api: '',
    lobbyUrl: '',
    gameList: [],
    prefix: '',
    promotions: [],
    trending: [],
  },
  webConfig: {
    color: {},
    landing: {}
  },
  themeLanding: 'beach'
}
const PlayUrlContext = React.createContext(defaultState)

class PlayUrlProvider extends React.Component {
  state = {
    playUrl: "",
    gameData: [],
    categories: [],
    isShowMenu: true,
    isShowMenuMobile: false,
    isSearch: false,
    centralList: {
      api: '',
      lobbyUrl: '',
      gameList: [],
      prefix: '',
      trendings: [],
      promotions: [],
    },
    webConfig: {
      color: {},
      landing: {}
    },
    news: [],
    themeLanding: 'beach',
    toggleMenu: () => { },
  }
  toggleMenu = () => {
    this.setState({ isShowMenu: !this.state.isShowMenu })
  }
  toggleMenuMobile = () => {
    this.setState({ isShowMenuMobile: !this.state.isShowMenuMobile })
  }
  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch })
  }
  updateCategories = (value) => {
    this.setState({ categories: value })
  }
  setGameData = (value) => {
    this.setState({ gameData: value })
  }
  setThemeLanding = (value) => {
    this.setState({ themeLanding: value})
  }
  setCentralList = (value) => {
    this.setState({
      centralList: {
        api: value.api,
        lobbyUrl: value.lobbyUrl,
        gameList: value.gameList,
        prefix: value.prefix,
        trendings: value.trendings,
        promotions: value.promotions,
      }
    })
  }
  setWebConfig = (value) => {
    this.setState({
      webConfig: {
        color: value.color,
        logoWeb: value.logoWeb,
        favicon: value.favicon,
      }
    })
  }
  setNewsData = (value) => {
    this.setState({
        news: value
      })
  }
  isMobile =
    /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  isLogin = JSON.parse(localStorage.getItem('userData'))

  async componentDidMount() {
    if (typeof window !== 'undefined') {
      let prefix
      const type = 'landing'
      const hostname = window.location.hostname;
      const isAmbplay = !!hostname.split('.').find(items => items === 'ambplay')
      NProgress.start();
      if (isAmbplay) prefix = hostname.split('.')[0] === 'test-uat' ? 'test' : hostname.split('.')[0]
      else prefix = 'testthb-uat'
      const resAgent = await fetch(`${API_URL}${WEB_CONFIG}/${window.location.search}`, {
        method: 'POST'
      }).then(response => response.json())
      let color = {
        darkMode: "#00111e",
        forth: resAgent.data.landingPageTheme.forth,
        main: resAgent.data.landingPageTheme.main,
        primary: resAgent.data.landingPageTheme.primary,
        secondary: resAgent.data.landingPageTheme.secondary,
        third: resAgent.data.landingPageTheme.third,
        secondaryMain: resAgent.data.landingPageTheme.secondary_main
      }
      if (resAgent.status.code === 0) {
        this.setWebConfig({
          color,
          logoWeb: resAgent.data.image.landingPage.logo,
          favicon: resAgent.data.image.landingPage.favicon,
        })
        this.setThemeLanding('beach')
      }
      let resGames = await fetch(`${API_URL}${GAME_LIST}/${window.location.search}`, {
        method: 'POST'
      }).then(response => response.json())
      if (resGames.status.code === 0) {
        this.setCentralList({
          lobbyUrl: resGames.data.lobby,
          gameList: resGames.data.games,
          prefix: resAgent.data.agentPrefix,
          promotions: resGames.data.promotions,
          trendings: resGames.data.trendings,
        })
        this.setGameData(resGames.data.games)
      }
      let resNew = await fetch(`${API_URL}${EVENT_NEW}`, {
        method: 'POST'
      }).then(response => response.json())
      if (resNew.status.code === 0) {
        this.setNewsData(resNew.data)
      }
      NProgress.done();
    }
  }
  render() {
    const { children } = this.props
    const { playUrl, guessUrl, isShowMenu, isShowMenuMobile, isSearch, isMobile, categories, gameData, centralList, webConfig, themeLanding, news } = this.state
    return (
      <PlayUrlContext.Provider
        value={{
          playUrl,
          guessUrl,
          isShowMenu,
          isShowMenuMobile,
          isSearch,
          isMobile,
          gameData,
          categories,
          centralList,
          webConfig,
          themeLanding,
          news,
          toggleMenu: this.toggleMenu,
          toggleMenuMobile: this.toggleMenuMobile,
          toggleSearch: this.toggleSearch,
          updateCategories: this.updateCategories,
          setGameData: this.setGameData,
          setQuery: this.setQuery,
        }}
      >
      {/* <ScrollToTop /> */}
        {children}
      </PlayUrlContext.Provider>
    )
  }
}
export default PlayUrlContext
export { PlayUrlProvider }
